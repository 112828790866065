

.email-window {
    font-family: 'Barlow Condensed', sans-serif;
    color: black;
    font-size: large;
    background-color: white;
}

.email-container {
    padding: 2rem 0 3rem 0;
    margin: 3em auto;
}

.email-bg-pic {
    background: transparent;
}


.helllo {
    margin: auto;
    width: 50% ;
    font-family: 'Barlow Condensed', sans-serif;
    color: white;
    font-size: 30px;
} 

.emailText {
    margin: 35px auto 5px auto;
    text-align: center;
    width: 50%;
    font-family: 'Barlow', sans-serif;
    color: white;
    text-shadow: black;
    font-size: 20px;
} 

.btn-email-send {
    background-color: white;
    color: black;
    text-align: center;
    font-family: 'Barlow', sans-serif;
    font-weight: 500;
    font-size: large;
    letter-spacing: 1px;
    border: 1px black solid;
    margin: 2px;
}

.btn-email-send:hover {
    background-color: black;
    color: white;
    text-decoration: none;
}


