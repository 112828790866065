
.aTag {
    width: 50px;
    padding: 5px 5px 25px 5px;
    z-index: 0;
}

.githubLogo {
    fill: white !important;
}


.aTag:hover {
    transition: ease .2s;
    transform: scale(1.3);
    
}

.footer {
    text-align: center;
    width: 100%;
    bottom:0;
}

#scrollBTN{
    padding-top: 10px;
    padding-left: 18px;
    padding-bottom: 5px;
    padding-right: 18px; 
    font-size: 25px;
    border-radius: 0;
}

.fa {
    background-color: black;
    float: right;
    position: fixed;
    bottom: 50px;
    right: 18px;
    z-index: 99;
    color: white;
    padding-top: 5px;
    padding-left: 15px;
    padding-bottom: 5px;
    padding-right: 15px; 
    font-size: 45px;
    border-radius: 0;
    border: 4px black solid;
}

.fa:hover {
    background-color: white;
    color: black;
}

@media (max-width: 600px){
    .fa{
        bottom: 40px;
        right: 15px;
        padding-top: 5px;
        padding-left: 10px;
        padding-bottom: 5px;
        padding-right: 10px; 
        font-size: 30px;
    }
    .aTag{
        width: 40px;
    padding: 5px 5px 25px 5px;
    }
}




